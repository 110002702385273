<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.99997 10.9999C3.44769 10.9999 2.99997 11.4477 2.99997 11.9999C2.99997 12.5522 3.44769 12.9999 3.99997 12.9999L11 12.9999L11 19.9999C11 20.5522 11.4477 20.9999 12 20.9999C12.5523 20.9999 13 20.5522 13 19.9999L13 13L20 13C20.5523 13 21 12.5522 21 12C21 11.4477 20.5523 11 20 11L13 11L13 3.99995C13 3.44767 12.5522 2.99995 12 2.99995C11.4477 2.99995 11 3.44767 11 3.99995L11 10.9999L3.99997 10.9999Z" fill="#8D9CA8"/>
  </svg>
</template>

<script>
  export default {
    name: "Add",
  };
</script>
