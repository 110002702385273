<template>
  <svg @click="openPreview(epackID)" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="39" height="39" rx="5.5" fill="white" stroke="#D6DEE2"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M21 11C21 10.4477 20.5523 10 20 10C19.4477 10 19 10.4477 19 11V19H11C10.4477 19 10 19.4477 10 20C10 20.5523 10.4477 21 11 21H19V29C19 29.5523 19.4477 30 20 30C20.5523 30 21 29.5523 21 29V21H29C29.5523 21 30 20.5523 30 20C30 19.4477 29.5523 19 29 19H21V11Z" fill="#8D9CA8"/>
  </svg>
</template>

<script>
  export default {
    name: "Plus",
    props: {
      openPreview: {
        type: Function,
        default: () => {},
      },
      epackID: {
        type: String,
        default: "",
      },
    },
  };
</script>
